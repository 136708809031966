export enum Color {
  Primary = '#211A0A',
  Inverted = '#fff',
  AccentPrimary = '#FFD531',
  AccentPrimaryDark = '#A17D1C',
  AccentPrimaryLight = '#F5F0DB',
  AccentSecondary = '#315bff',
  Danger = '#F44336',
  DangerLight = '#EF9A9A',
  Warning = '#ffc107',
  WarningLight = '#fff3cd',
  Success = '#155724',
  SuccessLight = '#d4edda',
  Info = '#004085',
  InfoLight = '#cce5ff',
}
